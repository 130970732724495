import React from "react";
import { connectRefinementList } from "react-instantsearch-dom";
import Flex from "app/components/Flex";
import { useDispatch } from "react-redux";
import { setShowAvailablToMeOnlyClassesAction } from "modules/user/actions";
import { H3 } from "app/components/Typography";
import { Switch } from "app/components/Switch";
import { useShowOnlyAvailableToMeOnlyClasses } from "modules/selectors";
import { SwitchWrapper } from "./styles";

const RefinementList = connectRefinementList(() => {
  const dispatch = useDispatch();
  const isShowOnlyAvailableToMeOnlyClasses = useShowOnlyAvailableToMeOnlyClasses();

  return (
    <SwitchWrapper>
      <H3>Available To Me</H3>
      <Switch
        onChange={() =>
          dispatch(
            setShowAvailablToMeOnlyClassesAction(
              !isShowOnlyAvailableToMeOnlyClasses
            )
          )
        }
        checked={isShowOnlyAvailableToMeOnlyClasses}
      />
    </SwitchWrapper>
  );
});

interface Props {
  defaultRefinement: [];
}

export function AvailableToMeToggle({ defaultRefinement, ...props }: Props) {
  return (
    <Flex {...props}>
      <RefinementList
        attribute="accessType"
        defaultRefinement={defaultRefinement}
      />
    </Flex>
  );
}
