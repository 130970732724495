import React from "react";
import { connectNumericMenu } from "react-instantsearch-dom";
import FilterOptions from "app/components/Algolia/FilterOptions";
import Div from "app/components/Div";
import { DurationItem } from "./types";

const NumericMenu = connectNumericMenu(({ items, refine, ...props }) => {
  const options = items
    .filter(i => i.value)
    .map(item => ({
      name: item.label,
      handleOnClick: () => (item.isRefined ? refine("") : refine(item.value)),
      active: item.isRefined,
    }));

  const activeCount = options.filter(option => option.active).length;

  return (
    <Div {...props}>
      <FilterOptions
        label={
          activeCount > 0 ? `Class Length (${activeCount})` : "Class Length"
        }
        options={options}
        gridTemplateColumns="repeat(2, 1fr)"
      />
    </Div>
  );
});

interface Props {
  durationItems: DurationItem[];
}

export function DurationOptions({ durationItems }: Props) {
  return <NumericMenu attribute="duration_in_seconds" items={durationItems} />;
}
