import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connectRefinementList } from "react-instantsearch-dom";
import FilterOptions from "app/components/Algolia/FilterOptions";
import { A } from "app/components/Typography";
import Flex from "app/components/Flex";
import Div from "app/components/Div";
import Icon, { ChevronDown } from "app/components/Icon";

const RefinementList = connectRefinementList(
  ({ items, sliceItemCount, refine, setItemCount }) => {
    let options = items
      .sort((x, y) => y.label - x.label)
      .map(item => ({
        name: item.label,
        handleOnClick: () => refine(item.value),
        active: item.isRefined,
      }));

    const activeCount = options.filter(option => option.active).length;

    // Algolia does not have a "showMore" feature for refinementLists in react
    // Implementing a custom version to toggle more IF the count is higher than threshold
    if (sliceItemCount) {
      options = options.slice(0, sliceItemCount);
    }

    useEffect(() => {
      setItemCount(items.length);
    }, [items, setItemCount]);

    return (
      <FilterOptions
        label={activeCount > 0 ? `Instructors (${activeCount})` : "Instructors"}
        options={options}
        gridTemplateColumns="repeat(2, 1fr)"
      />
    );
  }
);

export function InstructorOptions({ forceDefault = false, ...rest }) {
  const INSTRUCTOR_COUNT_THRESHOLD = 8;
  const [isShowingAllInstructors, toggleShowingAllInstructors] = useState(
    false
  );
  const [itemCount, setItemCount] = useState(0);
  if (forceDefault) {
    return null;
  }

  return (
    <Div {...rest}>
      <RefinementList
        attribute="instructor_name"
        limit={9999}
        transformItems={items =>
          items.sort((a, b) => (a.label > b.label ? 1 : -1))
        }
        sliceItemCount={!isShowingAllInstructors && INSTRUCTOR_COUNT_THRESHOLD}
        setItemCount={setItemCount}
      />
      {itemCount > INSTRUCTOR_COUNT_THRESHOLD && (
        <Flex alignItems="center" mt={2}>
          <A
            color="black"
            onClick={() =>
              toggleShowingAllInstructors(!isShowingAllInstructors)
            }
          >
            {isShowingAllInstructors ? "Hide" : "Show"} all instructors
          </A>
          <Icon
            ml={2}
            width="12px"
            as={ChevronDown}
            transform={isShowingAllInstructors && "rotate(180deg)"}
            color="black"
          />
        </Flex>
      )}
    </Div>
  );
}

InstructorOptions.propTypes = {
  forceDefault: PropTypes.bool,
};
