import React from "react";
import { connectRefinementList } from "react-instantsearch-dom";
import Flex from "app/components/Flex";
import { useDispatch } from "react-redux";
import { setShowExplicitAction } from "modules/user/actions";
import { H3 } from "app/components/Typography";
import { Switch } from "app/components/Switch";
import { useShouldShowExplicitMusic } from "modules/selectors";
import { SwitchWrapper } from "./styles";

const RefinementList = connectRefinementList(() => {
  const dispatch = useDispatch();
  const isExplicitMusicToggled = useShouldShowExplicitMusic();

  return (
    <SwitchWrapper>
      <H3>Allow Explicit Music</H3>
      <Switch
        onChange={() =>
          dispatch(setShowExplicitAction(!isExplicitMusicToggled))
        }
        checked={isExplicitMusicToggled}
      />
    </SwitchWrapper>
  );
});

interface Props {
  defaultRefinement: [];
}

export function ExplicitMusicToggle({ defaultRefinement, ...props }: Props) {
  return (
    <Flex {...props}>
      <RefinementList
        attribute="explicit"
        defaultRefinement={defaultRefinement}
      />
    </Flex>
  );
}
