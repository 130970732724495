import { AlgoliaIndexes } from "../types";

export const FILTERS = "NOT type:Program-Only";
export const DURATION_ITEMS = [
  {
    label: "5 min",
    end: 300,
  },
  {
    label: "10 min",
    start: 301,
    end: 600,
  },
  {
    label: "15 min",
    start: 601,
    end: 900,
  },
  {
    label: "20 min",
    start: 901,
    end: 1200,
  },
  {
    label: "30 min",
    start: 1201,
    end: 1800,
  },
  {
    label: "45 min",
    start: 1801,
    end: 2700,
  },
  {
    label: "60 min",
    start: 2701,
    end: 3600,
  },
  {
    label: "90 min",
    start: 3600,
  },
];
export const SORT_BY_ITEMS = [
  {
    value: AlgoliaIndexes.classesByPopularityRankingAsc,
    label: "Most Popular",
  },
  {
    value: AlgoliaIndexes.classesByTrendingRankingAsc,
    label: "Trending",
  },
  {
    value: AlgoliaIndexes.classesByDurationAsc,
    label: "Shortest",
  },
  {
    value: AlgoliaIndexes.classesByDurationDesc,
    label: "Longest",
  },
  {
    value: AlgoliaIndexes.classesByPublishDateDesc,
    label: "Newest",
  },
  {
    value: AlgoliaIndexes.classesByPublishDateAsc,
    label: "Oldest",
  },
];
