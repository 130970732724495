import { ClassList } from "app/components/ClassList";
import { ClassesInactive } from "app/components/Icon";
import InfiniteContentList from "app/components/InfiniteContentList";
import AlgoliaLoader from "app/components/Loader/AlgoliaLoader";
import { openFiltersAction } from "modules/filters";
import React from "react";
import {
  Configure,
  InstantSearch,
  RefinementList,
  NumericMenu,
  SortBy,
} from "react-instantsearch-dom";
import { useSelector, useDispatch } from "react-redux";
import { useFamilyModeSetting } from "hooks/useFamilyModeSetting";
import { useFamilyFriendlyFlag } from "hooks/useFeatureFlags";
import { searchClient } from "app/components/Algolia/constants";
import { useLocation } from "react-router";
import { Routes } from "constants/routes";
import { AlgoliaWidgetsCollectorContext } from "../WidgetsCollectorProvider";
import { DurationItem, SortByItem, AlgoliaIndexes } from "../types";

// @TODO create or abstract interfaces for these Algolia objects
interface Props {
  durationItems: DurationItem[];
  filters?: string;
  hitsPerPage: number;
  openFilter(): void;
  refinements: Record<string, unknown>;
  resultsState: Record<string, unknown>;
  searchState: Record<string, unknown>;
  selectedFrom?: string;
  setSearchState(newState: Record<string, unknown>): void;
  sortBy?: AlgoliaIndexes;
  sortByItems: SortByItem[];
}

interface RootState {
  filters: {
    classes: Record<string, unknown>[];
  };
  server: {
    initialAlgoliaResults: Record<string, unknown>[];
  };
}

const ClassResults = ({
  filters = "NOT type:Program-Only",
  durationItems,
  hitsPerPage = 12,
  refinements,
  searchState,
  selectedFrom = "ClassResults",
  setSearchState,
  sortBy = AlgoliaIndexes.classes,
  sortByItems,
  ...restOfPropsForClassList
}: Props) => {
  const location = useLocation();
  const { isFamilyModeOn } = useFamilyModeSetting();
  const isFamilyFeatureEnabled = useFamilyFriendlyFlag();
  const dispatch = useDispatch();
  const resultsState = useSelector(
    ({ server }: RootState) => server.initialAlgoliaResults
  );

  const onSearchStateChange = (newState: Record<string, unknown>) => {
    setSearchState({ newState });
  };

  return (
    <AlgoliaWidgetsCollectorContext.Consumer>
      {widgetsCollector => (
        <InstantSearch
          indexName={AlgoliaIndexes.classes}
          searchClient={searchClient}
          searchState={searchState}
          onSearchStateChange={onSearchStateChange}
          widgetsCollector={widgetsCollector}
          resultsState={resultsState}
        >
          <div style={{ display: "none" }}>
            <NumericMenu
              attribute="duration_in_seconds"
              items={durationItems}
            />
            <RefinementList
              attribute="style"
              defaultRefinement={refinements.style || []}
            />
            <RefinementList
              attribute="type"
              defaultRefinement={refinements.type || []}
            />
            <RefinementList
              attribute="explicit"
              defaultRefinement={refinements.explicit}
            />
            <RefinementList
              attribute="isFullyLicensed"
              defaultRefinement={refinements.isFullyLicensed}
            />
            <RefinementList
              attribute="accessType"
              defaultRefinement={refinements.accessType}
            />
            {isFamilyFeatureEnabled && (
              <RefinementList
                attribute="isFamilyFriendly"
                defaultRefinement={
                  isFamilyModeOn ? [true] : refinements.isFamilyFriendly
                }
              />
            )}
            <RefinementList
              attribute="level"
              defaultRefinement={refinements.level || []}
            />
            <RefinementList
              attribute="instructor_name"
              defaultRefinement={refinements.instructor_name || []}
            />
            <RefinementList
              attribute="categories"
              defaultRefinement={refinements.categories || []}
            />
            <SortBy defaultRefinement={sortBy} items={sortByItems} />
          </div>
          <Configure
            hitsPerPage={hitsPerPage}
            filters={filters}
            query={searchState.query}
          />
          <InfiniteContentList
            indexName={AlgoliaIndexes.classes}
            openFilter={() => dispatch(openFiltersAction("classes"))}
            renderList={({
              hits,
              sentinel,
            }: {
              hits: Record<string, unknown>[];
              sentinel: React.ReactNode;
            }) => {
              const classesToRender = hits;
              if (
                [
                  Routes.results__free_classes,
                  Routes.library__free_classes,
                ].includes(location.pathname as Routes)
              ) {
                classesToRender.splice(4, 5, {
                  type: "upgrade-card",
                });
              }

              return hits.length > 0 ? (
                <ClassList
                  classes={classesToRender}
                  selectedFrom={selectedFrom}
                  sentinel={sentinel}
                  {...restOfPropsForClassList}
                />
              ) : (
                <AlgoliaLoader icon={ClassesInactive} listType="classes" />
              );
            }}
          />
        </InstantSearch>
      )}
    </AlgoliaWidgetsCollectorContext.Consumer>
  );
};

export default ClassResults;
