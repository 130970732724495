import React from "react";
import { connectRefinementList } from "react-instantsearch-dom";
import Flex from "app/components/Flex";
import { useDispatch } from "react-redux";
import { setShowOnlyLicensedClassesAction } from "modules/user/actions";
import { H3 } from "app/components/Typography";
import { Switch } from "app/components/Switch";
import { useShowOnlyLicensedClasses } from "modules/selectors";
import { SwitchWrapper } from "./styles";

const RefinementList = connectRefinementList(() => {
  const dispatch = useDispatch();
  const isShowOnlyLicensedClassesToggled = useShowOnlyLicensedClasses();

  return (
    <SwitchWrapper>
      <H3>With Music Only</H3>
      <Switch
        onChange={() =>
          dispatch(
            setShowOnlyLicensedClassesAction(!isShowOnlyLicensedClassesToggled)
          )
        }
        checked={isShowOnlyLicensedClassesToggled}
      />
    </SwitchWrapper>
  );
});

interface Props {
  defaultRefinement: [];
}

export function WithMusicOnlyToggle({ defaultRefinement, ...props }: Props) {
  return (
    <Flex {...props}>
      <RefinementList
        attribute="isFullyLicensed"
        defaultRefinement={defaultRefinement}
      />
    </Flex>
  );
}
