import React from "react";
import { connectRefinementList } from "react-instantsearch-dom";
import Flex from "app/components/Flex";
import { H4 } from "app/components/Typography";
import { Switch } from "app/components/Switch";
import { SwitchWrapper } from "./styles";

const RefinementList = connectRefinementList(
  ({ currentRefinement, refine }) => {
    const boolCurrentRefinement = currentRefinement[0] === "true";
    return (
      <SwitchWrapper>
        <H4>Family Friendly</H4>
        <Switch
          onChange={() => {
            refine([String(!boolCurrentRefinement)]);
          }}
          checked={boolCurrentRefinement}
        />
      </SwitchWrapper>
    );
  }
);

interface Props {
  defaultRefinement: [];
}

export function FamilyFriendlyToggle({ defaultRefinement, ...props }: Props) {
  return (
    <Flex {...props}>
      <RefinementList
        attribute="isFamilyFriendly"
        defaultRefinement={defaultRefinement}
      />
    </Flex>
  );
}
